import {Controller} from "stimulus"
import {addComma, toNumber} from "../utils/utils";

export default class extends Controller {
    static targets = ['doMake', 'ids', 'id', 'toCheck', 'quantity', 'slipQuantity', 'slipLinesCount', 'destroy']

    calculateSummary() {
        const self = this
        const destroy = function(e, i) {
            return !self.destroyTargets[i].checked
        }
        const linesCount = this.quantityTargets.filter(destroy).map(e => 1).reduce((a, b) => a + b, 0)
        this.slipLinesCountTarget.value = addComma(linesCount)
        const quantity = this.quantityTargets.filter(destroy).map(e => toNumber(e.value)).reduce((a, b) => a + b, 0)
        this.slipQuantityTarget.value = addComma(quantity)
    }

    makeSlips(e) {
        const ids = []
        this.idTargets.map((e, i) => {
            const checked = this.toCheckTargets[i].checked
            if (checked) ids.push(e.value)
        })
        // if (!ids.length) {
        //     window.alert(I18n.t("messages.no_lines_selected"))
        //     return
        // }
        this.idsTarget.value = ids.join(",")
        this.doMakeTarget.click()
    }

}
