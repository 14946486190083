import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe', 'noHeader']

    getParams() {
        return `&supplier_single_form[no_header]=true&supplier_single_form[code]=${this.codeTarget.value}`
    }

    searchCode(e) {
        super._searchCode(e, 'suppliers')
    }

    checkCode() {
        super._checkCode('suppliers')
    }

}
