import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe']

    getParams() {
        if (this.hasStoreCodeTarget && this.storeCodeTarget.value) {
            return `&industry_single_form[no_header]=true&industry_single_form[code]=${this.codeTarget.value}&industry_single_form[store_code]=${this.storeCodeTarget.value}`
        } else {
            return `&industry_single_form[no_header]=true&industry_single_form[code]=${this.codeTarget.value}`
        }
    }

    searchCode(e) {
        super._searchCode(e, 'industries')
    }

}
