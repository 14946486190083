import {ParentController} from "./parent_controller";
import Rails from "@rails/ujs";

export default class extends ParentController {
    static targets = [
        // 'form',
        // 'regularNumber', 'regularWorkHours', 'regularWorkMinutes',
        // 'partNumber', 'partWorkHours', 'partWorkMinutes',
        'confirmed',
    ]

    keydown(e) {
        const $e = $(e.target)
        if (e.keyCode === 13) {
            e.preventDefault()
            const $td = $e.closest('td');
            let $nextTd = $td.closest('tr').next();
            let $input = null;

            while ($nextTd.length > 0) {
                const idx = $td.index();
                $input = $nextTd.children().eq(idx).find('input');
                if (!$input.prop('readonly')) {
                    break;
                }
                $nextTd = $nextTd.next();
            }

            if ($input && !$input.prop('readonly')) {
                $input.focus();
            }
        }
    }

    change() {
    }

}
