import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        'searchType',
        'storeCode', 'storeName', 'storeSearch',
        'articleCode', 'articleName', 'articleSearch',
        'customerCode', 'customerName', 'customerSearch',
        'consumerCode', 'consumerName', 'consumerSearch',
        'categoryCode', 'categoryName', 'categorySearch',
        'supplierCode', 'supplierName', 'supplierSearch',
    ]

    initialize() {
        this.checkSearchType();
    }

    checkSearchType() {
        let searchType = 0
        this.searchTypeTargets.forEach((el, i) => {
            if (el.checked) {
                searchType = ~~el.value
            }
        })
        // this.articleCodeTarget.disabled = true;this.articleNameTarget.disabled = true;this.articleSearchTarget.disabled = true;
        this.customerCodeTarget.disabled = true;this.customerNameTarget.disabled = true;this.customerSearchTarget.disabled = true;
        this.consumerCodeTarget.disabled = true;this.consumerNameTarget.disabled = true;this.consumerSearchTarget.disabled = true;
        this.categoryCodeTarget.disabled = true;this.categoryNameTarget.disabled = true;this.categorySearchTarget.disabled = true;
        this.supplierCodeTarget.disabled = true;this.supplierNameTarget.disabled = true;this.supplierSearchTarget.disabled = true;
        // this.storeCodeTarget.disabled = true;this.storeNameTarget.disabled = true;this.storeSearchTarget.disabled = true;
        switch (searchType) {
            case 1:
                // 取引先選択の場合、取引先、部門、商品、店舗で絞込
                this.customerCodeTarget.disabled = false;this.customerNameTarget.disabled = false;this.customerSearchTarget.disabled = false;
                this.categoryCodeTarget.disabled = false;this.categoryNameTarget.disabled = false;this.categorySearchTarget.disabled = false;
                break;
            case 2:
                // 顧客
                this.consumerCodeTarget.disabled = false;this.consumerNameTarget.disabled = false;this.consumerSearchTarget.disabled = false;
                this.categoryCodeTarget.disabled = false;this.categoryNameTarget.disabled = false;this.categorySearchTarget.disabled = false;
                break;
            case 3:
                // 仕入先選択の場合、仕入先、部門、商品、店舗で絞込
                this.supplierCodeTarget.disabled = false;this.supplierNameTarget.disabled = false;this.supplierSearchTarget.disabled = false;
                this.categoryCodeTarget.disabled = false;this.categoryNameTarget.disabled = false;this.categorySearchTarget.disabled = false;
                break;
            default:
                // 商品
                break;

        }
    }
}
