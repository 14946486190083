import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['dateFrom', 'dateTo']

    changeDate(e) {
        if (e && e.target.id === this.dateToTarget.id) return
        const dateFrom = moment(this.dateFromTarget.value, 'YYYY/MM/DD')
        if (dateFrom.isValid()) {
            const dateTo = dateFrom.add(1, 'month')
            this.dateToTarget.value = dateTo.format('YYYY/MM/DD')
        }
    }

    openModal(e) {
        const self = this;
        const $modal = $(this.modalTarget);
        $(this.iframeTarget).getIframe().setURL(self.getParams())
        window.simpleCloseModal = function () {
            $modal.modal('hide');
        }
        window.closeModal = function (data) {
            const $code = $(self.codeTarget)
            self.setResultsOnModalClose(data)
            $modal.modal('hide');
            if ($code.is('.code-check')) {
                self.checkCode();
                return
            }
            if($code.val()) {
                $code.focusNext($code.parents('form'))
            } else {
                $code.focus()
            }
            $code.forceChange()
        };
        $modal.modal('show');
    }

}
