import {ParentController} from "./parent_controller";
import {toNumber} from "../utils/utils";

export default class extends ParentController {
    static targets = ['date', 'customerCodes', 'outputInvoice', 'outputReceipt', 'outputTotalInvoice', 'outputDelivery',
        'toDivideReceiptFrom', 'dividedAmountFrom', 'ignoredAmountFrom', 'toDivideReceipt', 'dividedAmount', 'ignoredAmount',
        'fromNote', 'toNote']

    downloadInvoice(e) {
        e.preventDefault()
        this.copyNote()
        this.enableButtons()
        this.outputInvoiceTarget.click()
    }

    downloadTotalInvoice(e) {
        e.preventDefault()
        this.copyNote()
        this.enableButtons()
        this.outputTotalInvoiceTarget.click()
    }

    downloadDelivery(e) {
        e.preventDefault()
        this.copyNote()
        this.enableButtons()
        this.outputDeliveryTarget.click()
    }

    downloadReceipt(e) {
        e.preventDefault()
        if (this.toDivideReceiptFromTarget.checked) {
            this.toDivideReceiptTarget.value = this.toDivideReceiptFromTarget.value
        } else {
            this.toDivideReceiptTarget.value = 0
        }
        this.dividedAmountTarget.value = toNumber(this.dividedAmountFromTarget.value)
        this.ignoredAmountTarget.value = toNumber(this.ignoredAmountFromTarget.value)
        this.copyNote()
        this.enableButtons()
        this.outputReceiptTarget.click()
    }

    copyNote() {
        this.toNoteTarget.value = this.fromNoteTarget.value
    }

    enableButtons() {
        $(this.outputInvoiceTarget).removeAttr('data-disable-with');
        $(this.outputReceiptTarget).removeAttr('data-disable-with');
        $(this.outputTotalInvoiceTarget).removeAttr('data-disable-with');
        $(this.outputDeliveryTarget).removeAttr('data-disable-with');
    }

}
