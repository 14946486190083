import {ParentController} from "./parent_controller";
import {toNumber, addComma, confirmDialog} from "../utils/utils"

export default class extends ParentController {
    static targets = ['code', 'name', 'price', 'cost', 'unit', 'number', 'quantity', 'amount', 'taxType', 'delete', 'addLine', 'destroy']

    getParams() {
        // return `&item_single_form[no_header]=true&item_single_form[code]=${this.codeTarget.value}`
        return `&item_single_form[no_header]=true&item_single_form[name]=${this.nameTarget.value}`
    }

    setResultsOnModalClose(data) {
        this.codeTarget.value = (data && 'code' in data) ? data.code : '';
    }

    calculateAmount(e) {
        const targetId = e.target.id
        const price = toNumber(this.priceTarget.value)
        // const cost = toNumber(this.costTarget.value)
        const unit = toNumber(this.unitTarget.value)
        const number = toNumber(this.numberTarget.value)
        if (unit && number && targetId !== this.quantityTarget.id) {
            this.quantityTarget.value = addComma(unit * number)
        }

        const quantity = toNumber(this.quantityTarget.value)
        if (targetId !== this.amountTarget.id) {
            this.amountTarget.value = addComma(Math.floor(price * quantity))
        }
        $(this.amountTarget).forceChange()
        return true
    }

    delete() {
        const $target = $(this.deleteTarget)
        const $destroy = $(this.destroyTarget)
        confirmDialog($target.data('confirm-message'), function() {
            $target.closest('tr').addClass('d-none')
            $destroy.prop('checked', true)
            $destroy.forceChange()
        })
    }

    addLine() {
        const $target = $(this.addLineTarget)
        const index = $target.data('index')
        $("#input-lines_count_to_add").val(1)
        $("#input-line_index_to_add").val(index)
        $("#btn-add_lines").click()
    }

}
