import {Controller} from "stimulus"
import * as Utils from "../utils/chart_utils"
import {Chart} from "chart.js/auto"

export default class extends Controller {

    initialize() {
        super.initialize();
        this.prepare()
    }

    prepare() {

        const DATA_COUNT = 7;
        const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

        const data = {
            labels: window.labels,
            datasets: [
                {
                    label: $('#chart-dataset1-label').text(),
                    data: window.dataset1,
                    borderColor: Utils.CHART_COLORS.red,
                    backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
                    yAxisID: 'y',
                    order: 1
                },
                {
                    label: $('#chart-dataset2-label').text(),
                    data: window.dataset2,
                    borderColor: Utils.CHART_COLORS.blue,
                    backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
                    type: 'line',
                    yAxisID: 'y1',
                    order: 0
                }
            ]
        };

        console.log(data)
        const config = {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: $('#chart-title').text()
                    }
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    },
                }
            },
        };
        new Chart(document.getElementById('chart'), config)

    }
}

