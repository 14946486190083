import {ParentController} from "./parent_controller";

class MasterController extends ParentController {
    getParams() {
        return ''
    }

    checkCodeKey(e) {
        if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault()
            this.checkCode()
        }
    }

    setResultsOnModalClose(data) {
        const code = (data && 'code' in data) ? data.code : '';
        const name = (data && 'name' in data) ? data.name : '';
        if (this.hasCodeTarget) {
            this.codeTarget.value = code
            $(this.codeTarget).change()
        }
        if (this.hasNameTarget) {
            this.nameTarget.value = name
            $(this.nameTarget).change()
        }
    }

    openModal(e) {
        const self = this;
        const $modal = $(this.modalTarget);
        $(this.iframeTarget).getIframe().setURL(self.getParams())
        window.simpleCloseModal = function () {
            $modal.modal('hide');
        }
        window.closeModal = function (data) {
            self.setResultsOnModalClose(data)
            $modal.modal('hide');
            if (self.hasCodeTarget) {
                const $code = $(self.codeTarget)
                if ($code.is('.code-check')) {
                    self.checkCode();
                    return
                }
                if ($code.val()) {
                    $code.focusNext($code.parents('form'))
                } else {
                    $code.focus()
                }
                $code.forceChange()
            }
        };
        $modal.modal('show');
    }

    _searchCode(e, path) {
        const self = this
        // tab, enter, space
        if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            const $code = $(this.codeTarget);
            $code.removeFeedback()
            if (this.codeTarget.value) {
                $.ajax({
                    dataType: "json",
                    url: `/${path}/search/${this.codeTarget.value}`,
                    data: {},
                    success: function (data) {
                        if (data && data.length === 1) {
                            self.codeTarget.value = data[0].code
                            self.nameTarget.value = data[0].name
                        } else {
                            self.nameTarget.value = ""
                            $code.removeFeedback()
                            $code.addFeedback(I18n.t("messages.no_data_found"))
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            } else {
                this.nameTarget.value = ""
                // $code.addClass('is-invalid');
            }
            if (e.keyCode === 32) {
                e.preventDefault()
            }
        }
    }

    _checkCode(path) {
        const $code = $(this.codeTarget);
        $code.removeFeedback()
        if (this.codeTarget.value) {
            const noHeader = this.noHeaderTarget.value === "true";
            Turbolinks.visit(`/${path}/check/${this.codeTarget.value}?no_header=${noHeader}`);
        } else {
            if ($code.is('[required]')) $code.addFeedback(I18n.t("messages.add_code"))
        }
    }

    _searchCodes(e, path) {
        const self = this
        // tab, enter, space
        if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            const $code = $(this.codesTarget);
            $code.removeFeedback()
            if (this.codesTarget.value) {
                $.ajax({
                    dataType: "json",
                    url: `/${path}/searchs/${this.codesTarget.value}`,
                    data: {},
                    success: function (data) {
                        console.log(data)
                        if (data) {
                            self.namesTarget.value = data.names
                        } else {
                            self.namesTarget.value = ""
                            $code.removeFeedback()
                            $code.addFeedback(I18n.t("messages.no_data_found"))
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            } else {
                this.namesTarget.value = ""
                // $code.addClass('is-invalid');
            }
            // 複数入力できるようにスペースは許可
            // if (e.keyCode === 32) {
            //     e.preventDefault()
            // }
        }
    }

}

export {MasterController}