import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['sortItem1', 'sortItem2', 'sortItem3', 'sortItem4', 'dateFrom', 'dateTo']

    initialize() {
        this.checkSortItems();
    }

    changeDate(e) {
        if (e && e.target.id === this.dateToTarget.id) return
        const dateFrom = moment(this.dateFromTarget.value, 'YYYY/MM/DD')
        if (dateFrom.isValid()) {
            const dateTo = dateFrom.add(1, 'month')
            this.dateToTarget.value = dateTo.format('YYYY/MM/DD')
        }
    }

    checkSortItems(event) {
        if (event) {
            const nextVal = event.target.value;
            const num = ~~event.target.id.match(/\d/)[0];
            let i = 1;
            while (i < num) {
                if (i === 1 && this._checkVal(this.sortItem1Targets, nextVal)) {
                    event.target.checked = false;
                    return
                }
                if (i === 2 && this._checkVal(this.sortItem2Targets, nextVal)) {
                    event.target.checked = false;
                    return
                }
                if (i === 3 && this._checkVal(this.sortItem3Targets, nextVal)) {
                    event.target.checked = false;
                    return
                }
                if (i === 4 && this._checkVal(this.sortItem4Targets, nextVal)) {
                    event.target.checked = false;
                    return
                }
                i++;
            }

            let currentVal = null;
            if (num === 1) currentVal = this._uncheck(this.sortItem1Targets, nextVal);
            if (num === 2) currentVal = this._uncheck(this.sortItem2Targets, nextVal);
            if (num === 3) currentVal = this._uncheck(this.sortItem3Targets, nextVal);
            if (num === 4) currentVal = this._uncheck(this.sortItem4Targets, nextVal);
            i++;

            while (i <= 4) {
                if (i === 1) this._switchVal(this.sortItem1Targets, currentVal, nextVal);
                if (i === 2) this._switchVal(this.sortItem2Targets, currentVal, nextVal);
                if (i === 3) this._switchVal(this.sortItem3Targets, currentVal, nextVal);
                if (i === 4) this._switchVal(this.sortItem4Targets, currentVal, nextVal);
                i++;
            }
        }
    }

    _checkVal(targets, nextVal) {
        let val = false;
        targets.map(checkbox => {
            if (checkbox.checked && checkbox.value === nextVal) {
                val = true;
            }
        });
        return val;
    }

    _uncheck(targets, nextVal) {
        let val = null;
        targets.map(checkbox => {
            if (checkbox.checked && checkbox.value !== nextVal) {
                val = checkbox.value;
                checkbox.checked = false;
            }
        })
        return val;
    }

    _switchVal(targets, currentVal, nextVal) {
        if (targets.filter(checkbox => checkbox.checked && checkbox.value === nextVal).length > 0) {
            targets.map(checkbox => {
                checkbox.checked = (checkbox.value === currentVal)
            })
        }
    }
}
