import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['dateFrom', 'dateTo', 'targetDateFrom', 'targetDateTo']

    initialize() {
        this.changeDate();
    }

    changeDate(e) {
        if (e && e.target.id === this.targetDateToTarget.id) return
        const dateFrom = moment(this.dateFromTarget.value, 'YYYY/MM/DD')
        const dateTo = moment(this.dateToTarget.value, 'YYYY/MM/DD')
        let targetDateFrom
        if (e && e.target.id !== this.targetDateFromTarget.id) {
            targetDateFrom = dateFrom.clone().add(-1, 'years')
            if (targetDateFrom.isValid()) {
                this.targetDateFromTarget.value = targetDateFrom.format('YYYY/MM/DD')
            }
        } else {
            targetDateFrom = moment(this.targetDateFromTarget.value, 'YYYY/MM/DD')
        }
        const duration = dateTo.diff(dateFrom, 'days')
        const targetDateTo = targetDateFrom.clone().add(duration, 'days')
        if (targetDateTo.isValid()) {
            this.targetDateToTarget.value = targetDateTo.format('YYYY/MM/DD')
        }

        // console.debug(dateFrom, dateTo, targetDateFrom, targetDateTo, duration, this.targetDateFromTarget.value, this.targetDateToTarget.value)
    }
}
