import {ParentController} from "./parent_controller";

export default class extends ParentController {
    static targets = ['doOrder', 'ids', 'id', 'orderType', 'orderTypeHidden', 'toSend', 'downloadSlipId', 'doDownloadForm', 'doDownload']

    sendOrders(e) {
        const ids = []
        this.idTargets.map((e, i) => {
            const t = this.toSendTargets[i]
            if (t.checked) {
                // ids.push(e.value)
                ids.push(t.dataset.ordersSlipId)
            }
        })
        // if (!ids.length) {
        //     window.alert(I18n.t("messages.no_lines_selected"))
        //     return
        // }
        // this.idsTarget.value = ids.join(",")
        this.idsTarget.value = [...new Set(ids)].join(",")
        this.orderTypeTargets.forEach((el, i) => {
            if (el.checked) this.orderTypeHiddenTarget.value = el.value
        })
        this.doOrderTarget.click()
    }

    downloadOrders(e) {
        const ids = []
        this.idTargets.map((e, i) => {
            const t = this.toSendTargets[i]
            if (t.checked) {
                // ids.push(e.value)
                ids.push(t.dataset.ordersSlipId)
            }
        })
        const uniq_ids = [...new Set(ids)]
        uniq_ids.map((id) => {
            window.open('/orders/lines/download/' + id, '_blank')
            // this.downloadSlipIdTarget.value = id
            // this.doDownloadFormTarget.submit()
            // this.doDownloadTarget.click()
        })
    }

    changeOrderType(e) {
        const t = e.target
        this.orderTypeHiddenTarget.value = t.value
    }

    checkSend(e) {
        const t = e.target
        this.toSendTargets.map((e, i) => {
            if (~~e.dataset.ordersSlipId == ~~t.dataset.ordersSlipId) {
                e.checked = t.checked
            }
        })
    }

}
