import {ParentController} from './parent_controller'

export default class extends ParentController {
    static targets = ['form', 'addLines']

    addLines(e) {
        e.preventDefault()
        console.log('addLines')
        this.addLinesTarget.value = 1
        this.formTarget.target = ''
        this.formTarget.submit()
    }

}
