import LineController from "./line_controller"
import {toNumberAmount} from "../utils/utils";

export default class extends LineController {
    static targets = ['dateFrom', 'dateTo', 'date', 'customerId', 'customerStoreId', 'paymentDate', 'amount', 'cache', 'check', 'transfer', 'cache2', 'check2', 'transfer2', 'difference', 'hasMore']

    changeDate() {
    }

    initialize() {
        super.initialize();
        this.hasMoreTargets.map((e) => {
            this.triggerHasMore(e)
        })
    }

    changeHasMore(e) {
        this.triggerHasMore(e.target)
    }

    triggerHasMore(e) {
        let $next = $(e).closest('tr').next();
        if (e.checked) {
            $next.removeClass('d-none')
        } else {
            $next.addClass('d-none')
        }
    }

    calculateAmount(e) {
        for (let i = 0; i < this.amountTargets.length; i++) {
            const cache = toNumberAmount(this.cacheTargets[i].value)
            const check = toNumberAmount(this.checkTargets[i].value)
            const transfer = toNumberAmount(this.transferTargets[i].value)
            const cache2 = toNumberAmount(this.cache2Targets[i].value)
            const check2 = toNumberAmount(this.check2Targets[i].value)
            const transfer2 = toNumberAmount(this.transfer2Targets[i].value)
            const amount = cache + check + transfer + cache2 + check2 + transfer2
            this.amountTargets[i].value = amount
        }
    }

}
