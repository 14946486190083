import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe']

    getParams() {
        let code = ''
        if (this.hasCodeTarget) code = this.codeTarget.value
        // let name = ''
        // if (this.hasNameTarget) name = this.nameTarget.value
        return `&category_single_form[no_header]=true&category_single_form[code]=${code}`
    }

    searchCode(e) {
        super._searchCode(e, 'categories')
    }

}
