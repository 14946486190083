import SlipController from "./slip_controller"
import {addComma, isTrue, toNumber} from "../utils/utils";

export default class extends SlipController {
    static targets = ['code', 'terminalCode', 'storeCode', 'storeName', 'date', 'modal', 'iframe', 'noHeader', 'quantity', 'slipQuantity', 'slipLinesCount', 'destroy',]

    getParams() {
        return `&moves_slip_single_form[no_header]=true&moves_slip_single_form[code]=${this.codeTarget.value}&moves_slip_single_form[terminal_code]=${this.terminalCodeTarget.value}&moves_slip_single_form[store_code]=${this.storeCodeTarget.value}&moves_slip_single_form[date]=${this.dateTarget.value}`
    }

    checkOtherCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/moves_slips/new?terminal_code=${this.terminalCodeTarget.value}&store_code=${this.storeCodeTarget.value}&no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            this.codeTarget.value = ''
        }
    }

    checkCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.codeTarget.value && this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/moves_slips/check/${this.storeCodeTarget.value}/${this.terminalCodeTarget.value}/${this.codeTarget.value}?no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            $code.addClass('is-invalid');
        }
    }

    calculateSummary() {
        const self = this
        const destroy = function(e, i) {
            return !self.destroyTargets[i].checked
        }
        const linesCount = this.quantityTargets.filter(destroy).map(e => 1).reduce((a, b) => a + b, 0)
        this.slipLinesCountTarget.value = addComma(linesCount)
        const quantity = this.quantityTargets.filter(destroy).map(e => toNumber(e.value)).reduce((a, b) => a + b, 0)
        this.slipQuantityTarget.value = addComma(quantity)
    }

    initialize() {
        this.calculateSummary()
    }

}
