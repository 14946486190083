import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['dateFrom', 'dateTo']

    changeDate(e) {
        if (e && e.target.id === this.dateToTarget.id) return
        const dateFrom = moment(this.dateFromTarget.value, 'YYYY/MM/DD')
        if (dateFrom.isValid()) {
            const dateTo = dateFrom.add(1, 'month')
            this.dateToTarget.value = dateTo.format('YYYY/MM/DD')
        }
    }

}
