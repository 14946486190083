import LineController from "./line_controller"
import Rails from '@rails/ujs'
import {addComma, removeComma, toNumber} from "../utils/utils";

export default class extends LineController {
    static targets = ['code', 'name', 'price', 'cost', 'unit', 'number', 'quantity', 'amount', 'taxType', 'form', 'doUpdate', 'toSend', 'delete', 'addLine', 'destroy']

    calculateQuantity(e) {
        const targetId = e.target.id
        const unit = toNumber(this.unitTarget.value)
        const number = toNumber(this.numberTarget.value)
        if (unit && number && targetId !== this.quantityTarget.id) {
            this.quantityTarget.value = addComma(unit * number)
        }
    }

    toUpdate = false
    timeoutID = null

    // change
    calculateAmount(e) {
        const targetId = e.target.id
        const cost = toNumber(this.costTarget.value)
        this.costTarget.value = addComma(cost)
        const quantity = toNumber(this.quantityTarget.value)
        this.quantityTarget.value = addComma(quantity)
        if (targetId !== this.amountTarget.id) {
            this.amountTarget.value = addComma(cost * quantity)
        }
        this.amountTarget.value = addComma(toNumber(this.amountTarget.value))
        this.toUpdate = true
    }

    // focus
    clearTimer() {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
            this.timeoutID = null
        }
    }

    doUpdate() {
        const self = this
        self.costTarget.value = removeComma(self.costTarget.value)
        self.quantityTarget.value = removeComma(self.quantityTarget.value)
        self.amountTarget.value = removeComma(self.amountTarget.value)
        $(self.costTarget).addClass('bg-gray')
        $(self.quantityTarget).addClass('bg-gray')
        $(self.amountTarget).addClass('bg-gray')
        Rails.fire(self.formTarget, 'submit')
        self.toUpdate = false
    }

    // blur
    releaseTheHounds() {
        const self = this
        if (self.toUpdate) {
            this.timeoutID = setTimeout(function () {
                self.doUpdate()
            }, 1);
        }
    }

    onKeypress(e) {
        if (e.keyCode === 13) {
            this.doUpdate()
        }
    }

}
