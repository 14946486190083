import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe', 'storeCode']

    getParams() {
        if (this.hasStoreCodeTarget && this.storeCodeTarget.value) {
            return `&charge_single_form[no_header]=true&charge_single_form[code]=${this.codeTarget.value}&charge_single_form[store_code]=${this.storeCodeTarget.value}`
        } else {
            return `&charge_single_form[no_header]=true&charge_single_form[code]=${this.codeTarget.value}`
        }
    }

    searchCode(e) {
        super._searchCode(e, 'charges')
    }

}
