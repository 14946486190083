import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['searchType', 'targetDateFrom', 'targetDateTo', 'targetStoreCode', 'targetStoreName', 'targetSearch']

    initialize() {
        this.checkSearchType();
    }

    checkSearchType() {
        let toTargetDisable = false;
        this.searchTypeTargets.forEach((el, i) => {
            if (el.checked && ~~el.value === 0) {
                toTargetDisable = true;
            }
        })
        this.targetDateFromTarget.disabled = !toTargetDisable;
        this.targetDateToTarget.disabled = !toTargetDisable;
        this.targetStoreCodeTarget.disabled = toTargetDisable;
        this.targetStoreNameTarget.disabled = toTargetDisable;
        this.targetSearchTarget.disabled = toTargetDisable;
    }
}
