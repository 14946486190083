import {ParentController} from "./parent_controller";
import moji from "moji";

export default class extends ParentController {

    static targets = ['name', 'kana', 'shortName']

    startInput() {
        this.toInputKana = !(this.hasKanaTarget && this.kanaTarget.value)
    }

    createNames(e) {
        if(![8, 13, 32, 46].includes(e.keyCode) && (e.keyCode < 48 || e.keyCode > 90)) return
        const name = this.nameTarget.value
        if (!name) {
            this.toInputKana = true
            if(this.hasKanaTarget) this.kanaTarget.value = ""
            if(this.hasShortNameTarget) this.shortNameTarget.value = ""
            return
        }
        const r = /^[\u30A0-\u30FF\u3040-\u309F\uFF61-\uFF9F]+$/
        if (this.hasKanaTarget && this.toInputKana && r.test(name)) {
            this.kanaTarget.value = moji(name).convert("HG", "KK").convert("ZK", "HK").toString()
        }
        if(this.hasShortNameTarget) this.shortNameTarget.value = name.substring(0, Math.min(name.length, 16))
    }

}
