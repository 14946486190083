import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['codes', 'names', 'modal', 'iframe']

    getParams() {
        let params = `&stores_multiple_form[no_header]=true&stores_multiple_form[store_codes]=${this.codesTarget.value}`
        if (this.codesTarget.dataset.showAllCompany) {
            params = params + `&stores_multiple_form[show_all_company]=${this.codesTarget.dataset.showAllCompany}`
        }
        if (this.codesTarget.dataset.showAllShops) {
            params = params + `&stores_multiple_form[show_all_shops]=${this.codesTarget.dataset.showAllShops}`
        }
        if (this.codesTarget.dataset.showSelectAllCompany) {
            params = params + `&stores_multiple_form[show_select_all_company]=${this.codesTarget.dataset.showSelectAllCompany}`
        }
        if (this.codesTarget.dataset.showSelectAllShops) {
            params = params + `&stores_multiple_form[show_select_all_shops]=${this.codesTarget.dataset.showSelectAllShops}`
        }
        // if (this.codesTarget.dataset.useAllCompany) {
        //     params = params + `&stores_multiple_form[use_all_company]=1`
        // }
        // if (this.codesTarget.dataset.useAllShops) {
        //     params = params + `&stores_multiple_form[use_all_shops]=1`
        // }
        if (this.codesTarget.dataset.includeStorehouses) {
            params = params + `&stores_multiple_form[include_storehouses]=1`
        }
        return params
    }

    setResultsOnModalClose(data) {
        this.codesTarget.value = (data && 'codes' in data) ? data.codes : '';
        this.namesTarget.value = (data && 'names' in data) ? data.names : '';
    }

    searchCodes(e) {
        super._searchCodes(e, 'stores')
    }

}
