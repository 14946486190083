import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['id', 'code', 'name', 'modal', 'iframe', 'modalForm', 'iframeForm', 'price', 'cost', 'quantity', 'taxType', 'supplierCode', 'supplierName', 'categoryCode', 'categoryName', 'noHeader']

    checkCode() {
        super._checkCode('articles')
    }

    getPath() {
        return `/articles/`
    }

    getParams() {
        let code = ''
        if (this.hasCodeTarget) code = this.codeTarget.value
        let name = ''
        if (this.hasNameTarget) name = this.nameTarget.value
        return `&article_single_form[no_header]=true&article_single_form[code]=${code}&article_single_form[name]=${name}`
    }

    setResultsOnModalClose(data) {
        this.codeTarget.value = (data && 'code' in data) ? data.code : ''
        this.codeTarget.dataset.articleId = (data && 'id' in data) ? data.id : ''
        if (this.hasIdTarget) this.idTarget.value = (data && 'id' in data) ? data.id : ''
        if (this.hasNameTarget) this.nameTarget.value = (data && 'name' in data) ? data.name : ''
        if (this.hasPriceTarget) this.priceTarget.value = (data && 'price' in data) ? data.price : ''
        if (this.hasCostTarget) this.costTarget.value = (data && 'cost' in data) ? data.cost : ''
        if (this.hasQuantityTarget) this.quantityTarget.value = (data && 'quantity' in data) ? data.quantity : ''
        if (this.hasSupplierCodeTarget) this.supplierCodeTarget.value = (data && 'supplierCode' in data) ? data.supplierCode : ''
        if (this.hasSupplierNameTarget) this.supplierNameTarget.value = (data && 'supplierName' in data) ? data.supplierName : ''
        if (this.hasCategoryCodeTarget) this.categoryCodeTarget.value = (data && 'categoryCode' in data) ? data.categoryCode : ''
        if (this.hasCategoryNameTarget) this.categoryNameTarget.value = (data && 'categoryName' in data) ? data.categoryName : ''
        if (this.hasTaxTypeTarget) this.taxTypeTarget.value = (data && 'taxType' in data) ? data.taxType : ''
        if (this.hasPriceTarget) $(this.priceTarget).change()
    }

    openModalForm(e) {
        if (!this.codeTarget.dataset.articleId) return
        // TODO: articleId
        const path = `${this.getPath()}${this.codeTarget.dataset.articleId}?no_header=true`
        $(this.iframeFormTarget).getIframe().prop('src', path)
        $(this.modalFormTarget).modal('show');
    }

    // searchCode(e) {
    //     super._searchCode(e, 'articles')
    // }

    searchCode(e) {
        const path = "articles"
        const self = this
        if(e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            const $code = $(this.codeTarget);
            $code.removeFeedback()
            if (this.codeTarget.value) {
                $.ajax({
                    dataType: "json",
                    url: `/${path}/search/${this.codeTarget.value}`,
                    data: {},
                    success: function(datas) {
                        if (datas && datas.length === 1) {
                            const data = datas[0]
                            if (self.hasNameTarget) self.nameTarget.value = data.name
                            if (self.hasPriceTarget) self.priceTarget.value = (data && 'price' in data) ? data.price : '';
                            if (self.hasCostTarget) self.costTarget.value = (data && 'cost' in data) ? data.cost : '';
                            if (self.hasQuantityTarget) self.quantityTarget.value = (data && 'quantity' in data) ? data.quantity : '';
                            if (self.hasTaxTypeTarget) self.taxTypeTarget.value = (data && 'tax_type' in data) ? data.tax_type : '';
                            if (self.hasPriceTarget) $(self.priceTarget).change().select()
                            self.codeTarget.dataset.articleId = (data && 'id' in data) ? data.id : ''
                        } else {
                            self.nameTarget.value = ""
                            $code.removeFeedback()
                            $code.addFeedback(I18n.t("messages.no_data_found"))
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            } else {
                this.nameTarget.value = ""
                // $code.addClass('is-invalid');
            }
            if (e.keyCode === 32) {
                e.preventDefault()
            }
        }
    }

}
