function toNumber(v) {
    if (typeof v !== 'string') return null
    return Number(removeComma(v))
}
function hasLeft(e) {
    return (e.value && toNumber(e.value) !== 0) || (!e.value && toNumber(e.placeholder) !== 0)
}

function toNumberAmount(v) {
    if (typeof v !== 'string') return null
    return Number(removeCommaAmount(v))
}

function removeComma(v) {
    if (typeof v !== 'string') return null
    return ('' + v).replace(/,/g, '')
}

function addComma(v) {
    if (typeof v !== 'number') return null
    if (Number.isNaN(v)) return null
    if (v && !isNaN(v)) {
        v = v.toLocaleString()
    }
    return v
}

function removeCommaAmount(v) {
    v = removeComma(v)
    return ('' + v).replace(/^¥ /g, '')
}

function addCommaAmount(v) {
    v = addComma(v)
    return "¥ " + v
}

function isTrue(v) {
    return v === 'true' || (v === 'false' ? false : !!v)
}

function reduceSum(values) {
    return values.reduce((a, b) => a + b, 0)
}

function confirmDialog(message, callback) {
    let $dialog = $('#confirmation-modal')
    let $content = $dialog.find('#modal-content')
    let $ok = $dialog.find('#ok-button')
    $content.text(message)
    $ok.off('click');
    $ok.click(function (event) {
        event.preventDefault()
        $dialog.modal("hide")
        callback()
    })
    $dialog.modal("show")
    return false;
}

export {toNumber, addComma, removeComma, toNumberAmount, addCommaAmount, removeCommaAmount, isTrue, confirmDialog, reduceSum, hasLeft}