import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe']

    searchZipcodeKey(e) {
        if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault()

            this.searchZipcode()
        }
    }

    searchZipcode() {
        const self = this;
        const $modal = $(this.modalTarget);
        const $iframe = $(this.iframeTarget).getIframe()
        const s = this.codeTarget.value
        let url = `${$iframe.data('src')}&zipcode_single_form[no_header]=true&zipcode_single_form[s]=${s}`
        $.getJSON(url, function (data) {
            if (data.length === 1) {
                const row = data.pop()
                self.nameTarget.value = row.prefecture_name + row.city_name + row.area_name
            } else {
                $iframe.prop('src', encodeURI(url));
                window.simpleCloseModal = function () {
                    $modal.modal('hide');
                }
                window.closeModal = function (data) {
                    self.nameTarget.value = (data && 'name' in data) ? data.name : '';
                    $modal.modal('hide');
                    $(self.nameTarget).focus()
                };
                $modal.modal('show');
            }
        })
    }

    searchAddress() {
        const self = this;
        const $modal = $(this.modalTarget);
        $(this.iframeTarget).getIframe().setURL(`&zipcode_single_form[no_header]=true&zipcode_single_form[s]=${this.nameTarget.value}`)
        window.simpleCloseModal = function () {
            $modal.modal('hide');
        }
        window.closeModal = function (data) {
            self.codeTarget.value = (data && 'code' in data) ? data.code : '';
            $modal.modal('hide');
            $(self.codeTarget).focus()
        };
        $modal.modal('show');
    }

    getParams() {
        let s = ''
        if (this.hasNameTarget) {
            s = [this.codeTarget.value, this.nameTarget.value].join(' ')
        } else {
            s = this.codeTarget.value
        }
        return `&zipcode_single_form[no_header]=true&zipcode_single_form[s]=${s}`
    }

}
