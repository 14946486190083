import LineController from "./line_controller"
import {addComma, hasLeft, toNumber} from "../utils/utils";

export default class extends LineController {
    static targets = ['code', 'name', 'price', 'cost', 'deliveryPrice', 'profitRate', 'unit', 'number', 'quantityLeft', 'quantity', 'sumQuantity', 'addLine']

    initialize() {
        super.initialize();
        this.calculateAmount()
    }

    calculateAmount() {
        if (!this.nameTarget.value) return
        const number = toNumber(this.numberTarget.value)

        const sum = this.quantityTargets.reduce(function (sum, e) {
            return sum + toNumber(e.value)
        }, 0)
        this.sumQuantityTarget.value = sum
        const qlt = this.quantityLeftTarget
        if (this.hasProfitRateTarget) {
            const cost = toNumber(this.costTarget.value);
            const price = toNumber(this.deliveryPriceTarget.value);
            this.profitRateTarget.value = price === 0 ? null : Math.floor((1.0 - cost / price) * 10000) / 100
        }
        qlt.placeholder = number - sum
        if (hasLeft(qlt)) {
            $(qlt).addClass('text-danger').addClass('font-weight-bold')
        } else {
            $(qlt).removeClass('text-danger').removeClass('font-weight-bold')
        }

        this.numberTarget.value = addComma(number)
    }

}
