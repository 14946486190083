import SlipController from "./slip_controller"
import {addComma, isTrue, toNumber, reduceSum, hasLeft} from "../utils/utils";

export default class extends SlipController {
    static targets = ['code', 'terminalCode', 'storeCode', 'storeName', 'date', 'modal', 'iframe', 'noHeader',
        'unit', 'cost', 'number', 'amount', 'price', 'deliveryPrice', 'taxType', 'profitRate',
        'slipAmount', 'slipTax', 'slipTotal', 'slipProfitRate', 'slipProfitRateInput', 'destroy', 'supplierName', 'articleName',
        'buyAmount', 'storeAmount', 'storeProfitRate', 'deliveryAmount', 'storeQuantity', 'deliveryQuantity', 'deliveryProfitRate',
        'hasLeft', 'autoCalculateSummary']

    getParams() {
        return `&buys_group_slip_single_form[no_header]=true&buys_group_slip_single_form[code]=${this.codeTarget.value}&buys_group_slip_single_form[terminal_code]=${this.terminalCodeTarget.value}&buys_group_slip_single_form[store_code]=${this.storeCodeTarget.value}&buys_group_slip_single_form[date]=${this.dateTarget.value}`
    }

    checkOtherCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/buys_group_slips/new?terminal_code=${this.terminalCodeTarget.value}&store_code=${this.storeCodeTarget.value}&no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            this.codeTarget.value = ''
        }
    }

    checkCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.codeTarget.value && this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/buys_group_slips/check/${this.storeCodeTarget.value}/${this.terminalCodeTarget.value}/${this.codeTarget.value}?no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            $code.addClass('is-invalid');
        }
    }

    initialize() {
        this.calculateSummary()
    }

    aggValues(numbers, prices) {
        // 税額を求めなくて良い金額(内税 or 非課税)
        let costAmount0 = 0
        let costAmount8 = 0
        let costAmount10 = 0
        let costTax0 = 0
        let priceAmount0 = 0
        let priceAmount8 = 0
        let priceAmount10 = 0
        let priceTax0 = 0
        numbers.map((e, i) => {
            const unit = toNumber(this.unitTargets[i].value)
            const number = e
            // 推奨売価を使う
            const price = toNumber(prices[i].value || prices[i].placeholder || 0)
            const priceAmount = price * unit * number
            const cost = toNumber(this.costTargets[i].value)
            const costAmount = cost * number
            const taxType = 1 * this.taxTypeTargets[i].value
            // price
            switch (taxType) {
                case 1: // 外税10%
                    priceAmount10 += priceAmount // 合計してから税額を求める
                    break
                case 2: // 内税10%
                {
                    const price_tax = Math.floor(priceAmount / 1.1 * 0.1)
                    priceAmount0 += priceAmount - price_tax
                    priceTax0 += price_tax
                }
                    break
                case 3: // 外税8%
                    priceAmount8 += priceAmount // 合計してから税額を求める
                    break
                case 4: // 内税8%
                {
                    const price_tax = Math.floor(priceAmount / 1.1 * 0.1)
                    priceAmount0 += priceAmount - price_tax
                    priceTax0 += price_tax
                }
                    break
                case 0: // 非課税
                default:
                    priceAmount0 += priceAmount
                    break
            }
            // cost
            switch (taxType) {
                case 1: // 外税10%
                    costAmount10 += costAmount // 合計してから税額を求める
                    break
                case 2: // 内税10%
                {
                    const costTax = Math.floor(costAmount / 1.1 * 0.1)
                    costAmount0 += costAmount - costTax
                    costTax0 += costTax
                }
                    break
                case 3: // 外税8%
                    costAmount8 += costAmount
                    // 合計してから税額を求める
                    break
                case 4: // 内税8%
                {
                    const costTax = Math.floor(costAmount / 1.1 * 0.1)
                    costAmount0 += costAmount - costTax
                    costTax0 += costTax
                }
                    break
                case 0: // 非課税
                default:
                    costAmount0 += costAmount
                    break
            }
        })

        // 消費税の四捨五入
        // const priceTax8 = Math.round(priceAmount8 * 0.08)
        // const priceTax10 = Math.round(priceAmount10 * 0.1)
        const costTax8 = Math.round(costAmount8 * 0.08)
        const costTax10 = Math.round(costAmount10 * 0.1)

        const costSum = costAmount0 + costAmount8 + costAmount10
        const taxSum = costTax0 + costTax8 + costTax10
        const priceSum = priceAmount0 + priceAmount8 + priceAmount10
        const profitRate = costSum > 0 ? Math.floor((1.0 - costSum / priceSum) * 10000) / 100 : 0
        return {costSum, taxSum, priceSum, profitRate}
    }

    calculateSummary(e) {
        if ((e && e.target.id !== this.autoCalculateSummaryTarget.id) && !this.autoCalculateSummaryTarget.checked) return
        const numbers = this.numberTargets.map((e) => toNumber(e.value))
        let {costSum, taxSum, profitRate} = this.aggValues(numbers, this.priceTargets)

        this.slipAmountTarget.value = addComma(costSum)
        this.slipTaxTarget.value = addComma(taxSum)
        this.slipTotalTarget.value = addComma(costSum + taxSum)
        this.slipProfitRateTarget.value = profitRate
        this.slipProfitRateInputTarget.value = this.slipProfitRateTarget.value
        this.buyAmountTarget.value = this.slipAmountTarget.value

        const storeNumbers = this.numberTargets.map((e, i) => reduceSum($(e).closest('tr').find('.store-quantity').toArray().map((e) => toNumber(e.value))))
        let {costSum: storeCostSum, profitRate: storeProfitRate} = this.aggValues(storeNumbers, this.priceTargets)

        this.storeAmountTarget.value = addComma(storeCostSum)
        this.storeProfitRateTarget.value = storeProfitRate

        this.deliveryAmountTargets.map((e, i) => {
            const deliveryId = e.dataset.deliveryId
            const values = this.deliveryQuantityTargets.filter((e) => e.dataset.deliveryId === deliveryId).map((e) => toNumber(e.value))
            let {costSum, profitRate} = this.aggValues(values, this.deliveryPriceTargets)
            this.deliveryAmountTargets[i].value = addComma(costSum)
            this.deliveryProfitRateTargets[i].value = addComma(profitRate)
        })
        this.highlightLeft()
    }

    highlightLeft(e) {
        if (this.hasLeftTarget.checked) {
            $('tbody').find('tr.tr-line').addClass('d-none')
        } else {
            $('tbody').find('tr.tr-line').removeClass('d-none')
        }
        this.storeQuantityTargets.filter((e) => e.dataset.isMyStore === 'true').map((e) => {
            const $tr = $(e).closest('tr');
            if (this.hasLeftTarget.checked && hasLeft(e)) {
                $tr.addClass('bg-danger2').removeClass('d-none')
            } else {
                $tr.removeClass('bg-danger2')
            }
        })
    }

    changeSupplier(e) {
        let afterTarget = false
        this.supplierNameTargets.map((m, i) => {
            if (e.target === m) {
                afterTarget = true
            }
            if (afterTarget && !this.articleNameTargets[i].value) {
                m.value = e.target.value
            }

        })
    }
}
