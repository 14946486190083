import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['id', 'code', 'name', 'modal', 'iframe', 'price', 'cost', 'noHeader']

    checkCode() {
        super._checkCode('suppliers')
    }

    getPath() {
        return `/suppliers/`
    }

    getParams() {
        let name = ''
        if (this.hasNameTarget) name = this.nameTarget.value
        return `&supplier_seika_form[no_header]=true&supplier_seika_form[name]=${name}`
    }

    setResultsOnModalClose(data) {
        console.log(data)
        this.nameTarget.value = (data && 'shortName' in data) ? data.shortName : '';
        this.nameTarget.dataset.supplierId = (data && 'id' in data) ? data.id : '';
        // this.idTarget.dataset.supplierId = (data && 'id' in data) ? data.id : '';
    }

    // searchCode(e) {
    //     super._searchCode(e, 'suppliers')
    // }

    searchName(e) {
        const path = "suppliers"
        const self = this
        if(e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) {
            const $name = $(this.nameTarget);
            $name.removeFeedback()
            if (this.nameTarget.value) {
                $.ajax({
                    dataType: "json",
                    url: `/${path}/search_short_name/${this.nameTarget.value}`,
                    data: {},
                    success: function(datas) {
                        if (datas && datas.length === 1) {
                            const data = datas[0]
                            if (self.hasNameTarget) self.nameTarget.value = data.shortName
                            if (self.hasPriceTarget) self.priceTarget.value = (data && 'price' in data) ? data.price : '';
                            if (self.hasCostTarget) self.costTarget.value = (data && 'cost' in data) ? data.cost : '';
                        } else {
                            self.nameTarget.value = ""
                            $name.removeFeedback()
                            $name.addFeedback(I18n.t("messages.no_data_found"))
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            } else {
                this.nameTarget.value = ""
                // $name.addClass('is-invalid');
            }
            if (e.keyCode === 32) {
                e.preventDefault()
            }
        }
    }

    openModal(e) {
        const self = this;
        const $modal = $(this.modalTarget);
        $(this.iframeTarget).getIframe().setURL(self.getParams())
        window.simpleCloseModal = function () {
            $modal.modal('hide');
        }
        window.closeModal = function (data) {
            self.setResultsOnModalClose(data)
            $modal.modal('hide');
            if (self.hasCodeTarget) {
                const $code = $(self.codeTarget)
                if ($code.is('.code-check')) {
                    self.checkCode();
                    return
                }
                if($code.val()) {
                    $code.focusNext($code.parents('form'))
                } else {
                    $code.focus()
                }
                $code.forceChange()
            }
        };
        $modal.modal('show');
    }

}
