import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['code', 'name', 'name2', 'modal', 'iframe']

    searchZipcode() {
        const self = this;
        const $code = $(this.codeTarget)
        const s = $code.val()
        let url = `${$code.data('src')}?s=${s}`
        $.getJSON(url, function (data) {
            $code.removeFeedback()
            if (data.length === 1) {
                const row = data.pop()
                self.nameTarget.value = row.prefecture_name + row.city_name + row.area_name
                $(self.name2Target).focus()
            } else {
                $code.addFeedback("郵便番号が見つかりませんでした")
            }
        })
    }

    searchAddress() {
        const self = this;
        const $modal = $(this.modalTarget);
        $(this.iframeTarget).getIframe().setURL(`&no_header=true&s=${this.nameTarget.value}`)
        window.simpleCloseModal = function () {
            $modal.modal('hide');
        }
        window.closeModal = function (data) {
            console.log(data)
            self.codeTarget.value = (data && 'code' in data) ? data.code.replaceAll('-', '') : '';
            self.nameTarget.value = (data && 'name' in data) ? data.name : '';
            $modal.modal('hide');
            $(self.name2Target).focus()
        };
        $modal.modal('show');
    }

}
