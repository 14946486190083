import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['doMake', 'ids', 'id', 'toCheck']

    makeSlips(e) {
        const ids = []
        this.idTargets.map((e, i) => {
            const checked = this.toCheckTargets[i].checked
            if (checked) ids.push(e.value)
        })
        // if (!ids.length) {
        //     window.alert(I18n.t("messages.no_lines_selected"))
        //     return
        // }
        this.idsTarget.value = ids.join(",")
        this.doMakeTarget.click()
    }

}
