import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['codes', 'names', 'modal', 'iframe']

    getParams() {
        return `&categories_multiple_form[no_header]=true&categories_multiple_form[codes]=${this.codesTarget.value}`
    }

    setResultsOnModalClose(data) {
        this.codesTarget.value = (data && 'codes' in data) ? data.codes : '';
        this.namesTarget.value = (data && 'names' in data) ? data.names : '';
    }

    searchCode(e) {
        super._searchCode(e, 'categories')
    }

    searchCodes(e) {
        super._searchCodes(e, 'categories')
    }

}
