import SlipController from "./slip_controller"
import {addComma, isTrue, toNumber} from "../utils/utils";

export default class extends SlipController {
    static targets = ['code', 'terminalCode', 'storeCode', 'storeName', 'date', 'modal', 'iframe', 'noHeader', 'quantity', 'slipQuantity', 'slipLinesCount', 'destroy',]

    calculateSummary() {
        const self = this
        const destroy = function(e, i) {
            return !self.destroyTargets[i].checked
        }
        const linesCount = this.quantityTargets.filter(destroy).map(e => 1).reduce((a, b) => a + b, 0)
        this.slipLinesCountTarget.value = addComma(linesCount)
        const quantity = this.quantityTargets.filter(destroy).map(e => toNumber(e.value)).reduce((a, b) => a + b, 0)
        this.slipQuantityTarget.value = addComma(quantity)
    }

    initialize() {
        this.calculateSummary()
    }

}
