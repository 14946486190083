import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['code', 'name', 'modal', 'iframe', 'searchType', 'noHeader']

    getParams() {
        let params = `&store_single_form[no_header]=true&store_single_form[store_code]=${this.codeTarget.value}`
        if (this.codeTarget.dataset.showAllCompany) {
            params = params + `&store_single_form[show_all_company]=1`
        }
        if (this.codeTarget.dataset.showAllShops) {
            params = params + `&store_single_form[show_all_shops]=1`
        }
        if (this.codeTarget.dataset.includeStorehouses) {
            params = params + `&store_single_form[include_storehouses]=${this.codeTarget.dataset.includeStorehouses}`
        }
        if (this.codeTarget.dataset.forNews) {
            let v = 0
            this.searchTypeTargets.forEach((el, i) => {
                if (el.checked) {
                    v = el.value;
                }
            });
            params = params + `&store_single_form[for_group]=` + v
        }
        return params
    }

    searchCode(e) {
        if (this.codeTarget.dataset.showAllCompany && this.codeTarget.value === I18n.t("label.all_company_code")) {
            this.nameTarget.value = I18n.t("label.all_company_name")
        } else if (this.codeTarget.dataset.showAllShops && this.codeTarget.value === I18n.t("label.all_shops_code")) {
            this.nameTarget.value = I18n.t("label.all_shops_name")
        } else {
            super._searchCode(e, 'stores')
        }

    }

    checkCode() {
        super._checkCode('stores')
    }

}
