import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['searchType',
        'customerCode', 'customerName', 'customerSearch',
        'consumerCode', 'consumerName', 'consumerSearch',
    ]

    initialize() {
        this.checkSearchType();
    }

    checkSearchType() {
        let isConsumer = false;
        this.searchTypeTargets.forEach((el, i) => {
            if (el.checked && ~~el.value === 1) {
                isConsumer = true;
            }
        })
        this.customerCodeTarget.disabled = isConsumer;
        this.customerNameTarget.disabled = isConsumer;
        this.customerSearchTarget.disabled = isConsumer;
        this.consumerCodeTarget.disabled = !isConsumer;
        this.consumerNameTarget.disabled = !isConsumer;
        this.consumerSearchTarget.disabled = !isConsumer;
    }
}
