import LineController from "./line_controller";
import {toNumber, addComma} from "../utils/utils"

export default class extends LineController {
    static targets = ['code', 'name', 'unit', 'number', 'quantity', 'destroy', 'delete']

    calculateQuantity(e) {
        const targetId = e.target.id
        const unit = toNumber(this.unitTarget.value)
        const number = toNumber(this.numberTarget.value)
        if (unit && number && targetId !== this.quantityTarget.id) {
            this.quantityTarget.value = addComma(unit * number)
        }
    }

}
