import LineController from "./line_controller"
import Rails from '@rails/ujs'
import {addComma, removeComma, toNumber} from "../utils/utils";

export default class extends LineController {
    static targets = ['articleQuantity', 'specialCost', 'specialPrice', 'expirationDate', 'specialDeadline', 'quantityFrom', 'quantityTo', 'quantityBook', 'number', 'quantity', 'form']

    // change
    calculateQuantity(event) {
        const targetId = event.target.id

        const articleQuantity = 1.0 * toNumber(this.articleQuantityTarget.value)
        this.numberTargets.map((e, i) => {
            if (targetId === e.id) {
                // if (targetId !== this.quantityTargets[i].id) {
                this.quantityTargets[i].value = addComma(articleQuantity * toNumber(e.value))
                // }
            }
        })

        const quantitySum = this.quantityTargets.reduce((sum, e) => {
            return sum + toNumber(e.value)
        }, 0)

        this.quantityToTarget.value = addComma(1.0 * toNumber(this.quantityFromTarget.value) - quantitySum)
    }

}
