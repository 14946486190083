import SlipController from "./slip_controller"
import {addComma, isTrue, toNumber, hasLeft} from "../utils/utils";

export default class extends SlipController {
    static targets = ['buyDate', 'buyDateSearch',
        'registerType', 'registerTypeSearch',
        'deliveryId', 'deliveryIdSearch',
        'marketCode', 'marketCodeSearch',
        'formSearch', 'storeAmount', 'storeProfitRate', 'deliveryAmount', 'code', 'articleName', 'terminalCode', 'storeCode', 'date', 'modal', 'iframe', 'noHeader',
        'taxType', 'unit', 'number', 'cost', 'quantity', 'deliveryPrice', 'profitRate', 'quantityLeft', 'sumQuantity', 'hasLeft', 'noQuantity', 'searchName',
    ]

    initialize() {
        this.changeRegisterType()
        this.calculateAmount()
    }

    search() {
        this.registerTypeSearchTarget.value = this.getRegisterType()
        this.buyDateSearchTarget.value = this.buyDateTarget.value
        this.deliveryIdSearchTarget.value = this.deliveryIdTarget.value
        this.marketCodeSearchTarget.value = this.marketCodeTarget.value
        this.formSearchTarget.submit()

    }

    getRegisterType() {
        let registerType = 0;
        this.registerTypeTargets.forEach((el, i) => {
            if (el.checked) {
                registerType = ~~el.value;
            }
        })
        return registerType
    }


    changeRegisterType(e) {
        const registerType = this.getRegisterType()
        this.marketCodeTarget.disabled = (registerType === 1)
        this.marketCodeSearchTarget.disabled = (registerType === 1)
        this.deliveryIdTarget.disabled = (registerType === 0)
        this.deliveryIdSearchTarget.disabled = (registerType === 0)
    }


    getParams() {
        return `&sales_group_slip_single_form[no_header]=true&sales_group_slip_single_form[code]=${this.codeTarget.value}&sales_group_slip_single_form[terminal_code]=${this.terminalCodeTarget.value}&sales_group_slip_single_form[store_code]=${this.storeCodeTarget.value}&sales_group_slip_single_form[date]=${this.dateTarget.value}`
    }

    checkOtherCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/sales_group_slips/new?terminal_code=${this.terminalCodeTarget.value}&store_code=${this.storeCodeTarget.value}&no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            this.codeTarget.value = ''
        }
    }

    checkCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.codeTarget.value && this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/sales_group_slips/check/${this.storeCodeTarget.value}/${this.terminalCodeTarget.value}/${this.codeTarget.value}?no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            $code.addClass('is-invalid');
        }
    }


    aggValues(values, prices) {
        // 税額を求めなくて良い金額(内税 or 非課税)
        let costAmount0 = 0
        let costAmount8 = 0
        let costAmount10 = 0
        let costTax0 = 0
        let priceAmount0 = 0
        let priceAmount8 = 0
        let priceAmount10 = 0
        let price_tax0 = 0
        values.map((e, i) => {
            const unit = toNumber(this.unitTargets[i].value)
            const number = e
            // 推奨売価を使う
            const price = toNumber(prices[i].value || prices[i].placeholder || 0)
            // const price = 1.0 * toNumber(this.amountTargets[i].value || this.amountTargets[i].placeholder || 0)
            const priceAmount = price * unit * number
            const cost = toNumber(this.costTargets[i].value)
            const costAmount = cost * number
            const taxType = 1 * this.taxTypeTargets[i].value

            switch (taxType) {
                case 1: // 外税10%
                    priceAmount10 += priceAmount
                    // 合計してから税額を求める
                    break
                case 2: // 内税10%
                    // priceAmount0 += 1.0 * priceAmount / 1.1
                    // tax0 += 1.0 * priceAmount / 1.1 * 0.1
                {
                    const price_tax = Math.floor(priceAmount / 1.1 * 0.1)
                    priceAmount0 += priceAmount - price_tax
                    price_tax0 += price_tax
                }
                    break
                case 3: // 外税8%
                    priceAmount8 += priceAmount
                    // 合計してから税額を求める
                    break
                case 4: // 内税8%
                    // priceAmount0 += 1.0 * priceAmount / 1.08
                    // tax0 += 1.0 * priceAmount / 1.08 * 0.08
                {
                    const price_tax = Math.floor(priceAmount / 1.1 * 0.1)
                    priceAmount0 += priceAmount - price_tax
                    price_tax0 += price_tax
                }
                    break
                case 0: // 非課税
                default:
                    priceAmount0 += priceAmount
                    break
            }

            switch (taxType) {
                case 1: // 外税10%
                    costAmount10 += costAmount
                    // 合計してから税額を求める
                    break
                case 2: // 内税10%
                    // costAmount0 += 1.0 * costAmount / 1.1
                    // tax0 += 1.0 * costAmount / 1.1 * 0.1
                {
                    const costTax = Math.floor(costAmount / 1.1 * 0.1)
                    costAmount0 += costAmount - costTax
                    costTax0 += costTax
                }
                    break
                case 3: // 外税8%
                    costAmount8 += costAmount
                    // 合計してから税額を求める
                    break
                case 4: // 内税8%
                    // costAmount0 += 1.0 * costAmount / 1.08
                    // tax0 += 1.0 * costAmount / 1.08 * 0.08
                {
                    const costTax = Math.floor(costAmount / 1.1 * 0.1)
                    costAmount0 += costAmount - costTax
                    costTax0 += costTax
                }
                    break
                case 0: // 非課税
                default:
                    costAmount0 += costAmount
                    break
            }
        })

        // 消費税の四捨五入
        // const price_tax8 = Math.round(priceAmount8 * 0.08)
        // const price_tax10 = Math.round(priceAmount10 * 0.1)
        const costTax8 = Math.round(costAmount8 * 0.08)
        const costTax10 = Math.round(costAmount10 * 0.1)

        const costSum = costAmount0 + costAmount8 + costAmount10
        const taxSum = costTax0 + costTax8 + costTax10
        const priceSum = priceAmount0 + priceAmount8 + priceAmount10
        const profitRate = costSum > 0 ? Math.floor((1.0 - costSum / priceSum) * 10000) / 100 : 0
        return {costSum, taxSum, priceSum, profitRate}
    }

    calculateAmount(e) {
        this.storeAmountTargets.map((e, i) => {
            const storeId = e.dataset.storeId
            const values = this.quantityTargets.filter((e) => e.dataset.storeId === storeId).map((e) => toNumber(e.value))
            let {costSum, profitRate} = this.aggValues(values, this.deliveryPriceTargets)
            this.storeAmountTargets[i].value = addComma(costSum)
            this.storeProfitRateTargets[i].value = addComma(profitRate)
        })

        if ((this.hasSearchNameTarget && this.searchNameTarget.value) || (this.hasNoQuantityTarget && this.noQuantityTarget.checked) || this.hasLeftTarget.checked) {
            $('tbody').find('tr.tr-line').addClass('d-none')
        } else {
            $('tbody').find('tr.tr-line').removeClass('d-none')
        }
        this.highlightSearchName(e)
        this.highlightNoQuantity(e)
        this.highlightLeft(e)
    }

    highlightSearchName(ev) {
        if (!this.hasSearchNameTarget) return
        this.articleNameTargets.map((e, i) => {
            if (!e.value) return
            const $tr = $(e).closest('tr');
            const v = this.searchNameTarget.value
            if (v && e.value.includes(v)) {
                $(e).addClass('bg-info2')
                $tr.addClass('bg-info2').removeClass('d-none')
            } else {
                $(e).removeClass('bg-info2')
                $tr.removeClass('bg-info2')
            }

        })
    }

    highlightNoQuantity(ev) {
        if (!this.hasNoQuantityTarget) return
        this.sumQuantityTargets.map((e, i) => {
            if (!this.articleNameTargets[i].value) return
            const $tr = $(e).closest('tr');
            if (this.noQuantityTarget.checked && toNumber(e.value) === 0 && toNumber(this.quantityLeftTargets[i].value) !== 0) {
                $(e).addClass('bg-warning2')
                $tr.addClass('bg-warning2').removeClass('d-none')
            } else {
                $(e).removeClass('bg-warning2')
                $tr.removeClass('bg-warning2')
            }

        })
    }

    highlightLeft(ev) {
        this.quantityLeftTargets.map((e, i) => {
            if (!this.articleNameTargets[i].value) return
            const $tr = $(e).closest('tr');
            if (this.hasLeftTarget.checked && hasLeft(e)) {
                // $(e).addClass('text-danger').addClass('font-weight-bold')
                $tr.addClass('bg-danger2').removeClass('d-none')
            } else {
                // $(e).removeClass('text-danger').removeClass('font-weight-bold')
                $tr.removeClass('bg-danger2')
            }

        })
    }

}
