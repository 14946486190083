import LineController from "./line_controller"
import Rails from '@rails/ujs'
import {confirmDialog, removeComma} from "../utils/utils";

export default class extends LineController {
    static targets = ['form', 'cost', 'quantity', 'delete', 'destroy', 'deleteLink']

    delete(e) {
        e.preventDefault()
        const self = this
        const $target = $(this.deleteTarget)
        confirmDialog($target.data('confirm-message'), function () {
            $target.closest('tr').addClass('table-danger')
            $(self.destroyTarget).prop('checked', true)
            Rails.fire(self.formTarget, 'submit')
        })
    }

    doUpdate() {
        const self = this
        $(self.quantityTarget).addClass('bg-warning')
        self.quantityTarget.value = removeComma(self.quantityTarget.value)
        $(self.quantityTarget).addClass('bg-gray')
        Rails.fire(self.formTarget, 'submit')
    }

    onChange(e) {
        if(this.toUpdate) {
            this.toUpdate = false
            this.doUpdate()
        }
    }

    onKeypress(e) {
        this.toUpdate = true
        if (e.keyCode === 13) {
            this.toUpdate = false
            e.preventDefault()
            $(e.target).focusNext($('#tbody-parent'))
            this.doUpdate()
        }
    }

}
