import SlipController from "./slip_controller"
import {isTrue} from "../utils/utils";

export default class extends SlipController {
    static targets = ['code', 'terminalCode', 'storeCode', 'storeName', 'date', 'modal', 'iframe', 'noHeader', 'quantity', 'amount', 'taxType', 'slipQuantity', 'slipAmount', 'slipTax', 'slipTotal']

    getParams() {
        return `&buys_slip_single_form[no_header]=true&buys_slip_single_form[code]=${this.codeTarget.value}&buys_slip_single_form[terminal_code]=${this.terminalCodeTarget.value}&buys_slip_single_form[store_code]=${this.storeCodeTarget.value}&buys_slip_single_form[date]=${this.dateTarget.value}`
    }

    checkOtherCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/buys_slips/new?terminal_code=${this.terminalCodeTarget.value}&store_code=${this.storeCodeTarget.value}&no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            this.codeTarget.value = ''
        }
    }

    checkCode() {
        const $code = $(this.codeTarget);
        $code.removeClass('is-valid').removeClass('is-invalid');
        $code.next('.valid-feedback').hide();
        if (this.codeTarget.value && this.terminalCodeTarget.value && this.storeCodeTarget.value) {
            Turbolinks.visit(`/buys_slips/check/${this.storeCodeTarget.value}/${this.terminalCodeTarget.value}/${this.codeTarget.value}?no_header=${isTrue($(this.noHeaderTarget).val())}`);
        } else {
            $code.addClass('is-invalid');
        }
    }

    initialize() {
        this.calculateSummary()
    }

}
