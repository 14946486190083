import {MasterController} from './master_controller'

export default class extends MasterController {
    static targets = ['codes', 'names', 'modal', 'iframe']

    getParams() {
        let params = `&customers_multiple_form[no_header]=true&customers_multiple_form[codes]=${this.codesTarget.value}`
        if (this.codesTarget.dataset.showCorp) {
            params = params + `&customers_multiple_form[show_corp]=${this.codesTarget.dataset.showCorp}`
        }
        return params
    }

    setResultsOnModalClose(data) {
        this.codesTarget.value = (data && 'codes' in data) ? data.codes : '';
        if(this.hasNamesTarget) {
            this.namesTarget.value = (data && 'names' in data) ? data.names : '';
        }
    }

    searchCodes(e) {
        super._searchCodes(e, 'customers')
    }

}
