import {MasterController} from './master_controller'

export default class extends MasterController {

    static targets = ['code', 'noHeader', 'instoreCode']

    checkCode() {
        super._checkCode('articles')
    }

    addCheckDigit() {
        let code = this.codeTarget.value
        if (!code) return
        this.codeTarget.value = code + this.getEANCheckDigit(code)
        const $code = $(self.codeTarget)
        if ($code.is('.code-check')) {
            this.checkCode()
        }
    }

    setInstoreCode(e) {
        this.codeTarget.value = this.instoreCodeTarget.dataset.code
        const $code = $(self.codeTarget)
        if ($code.is('.code-check')) {
            this.checkCode()
        }
    }

    getEANCheckDigit(barcodeStr) {
        let evenNum = 0, oddNum = 0;
        for (let i = 0; i < barcodeStr.length; i++) {
            if (i % 2 === 0) {
                oddNum += parseInt(barcodeStr[i]);
            } else {
                evenNum += parseInt(barcodeStr[i]);
            }
        }
        return (10 - parseInt((evenNum * 3 + oddNum).toString().slice(-1))).toString().slice(-1)
    }
}
