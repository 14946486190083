import {ParentController} from "./parent_controller";
import Rails from "@rails/ujs";

export default class extends ParentController {
    static targets = ['form',
        'regularNumber', 'regularWorkHours', 'regularWorkMinutes',
        'partNumber', 'partWorkHours', 'partWorkMinutes',
        'confirmed',
    ]

    initialize() {
        super.initialize();
        const $e = $(this.confirmedTarget)
        $e.closest('.tr-work_time').find('.td-input input').prop('readonly', $e.prop('checked'))
    }

    keydown(e) {
        const $e = $(e.target)
        if (e.shiftKey && e.keyCode === 13) {
            const name = $e.prop('name')
            $e.closest('.tr-work_time').next().find(`input[name="${name}"]`).focus()
            Rails.fire(this.formTarget, 'submit')
        } else if (e.keyCode === 13) {
            $e.focusNext($e.closest('tbody'))
        }
    }

    save(e) {
        Rails.fire(this.formTarget, 'submit')
    }

    change(e) {
        const $e = $(e.target)
        $e.addClass('bg-warning')
    }

    checkConfirmed(e) {
        const $e = $(e.target)
        $e.closest('.tr-work_time').find('.td-input input').prop('readonly', $e.prop('checked'))
        Rails.fire(this.formTarget, 'submit')
    }
}
