import {MasterController} from './master_controller'
import {addComma, toNumber, isTrue} from "../utils/utils";

export default class extends MasterController {
    static targets = ['code', 'terminalCode', 'storeCode', 'storeName', 'date', 'modal', 'iframe', 'noHeader', 'quantity', 'amount', 'taxType', 'slipQuantity', 'slipAmount', 'slipTax', 'slipTotal', 'destroy']

    getParams() {
        // return `&whole_sales_slip_single_form[no_header]=true&whole_sales_slip_single_form[code]=${this.codeTarget.value}&whole_sales_slip_single_form[terminal_code]=${this.terminalCodeTarget.value}&whole_sales_slip_single_form[store_code]=${this.storeCodeTarget.value}&whole_sales_slip_single_form[date]=${this.dateTarget.value}`
        throw new Exception("not implemented")
    }

    setResultsOnModalClose(data) {
        this.codeTarget.value = (data && 'code' in data) ? data.code : '';
        this.terminalCodeTarget.value = (data && 'terminalcode' in data) ? data.terminalcode : '';
        this.storeCodeTarget.value = (data && 'storecode' in data) ? data.storecode : '';
    }

    checkOtherCode() {
        throw new Exception("not implemented")
    }

    checkCode() {
        throw new Exception("not implemented")
    }

    initialize() {
        this.calculateSummary()
    }

    calculateSummary() {
        const self = this
        const destroy = function(e, i) {
            return !self.destroyTargets[i].checked
        }
        const quantity = this.quantityTargets.filter(destroy).map(e => toNumber(e.value)).reduce((a, b) => a + b, 0)
        this.slipQuantityTarget.value = addComma(quantity)

        // 税額を求めなくて良い金額(内税 or 非課税)
        let amount0 = 0
        let amount8 = 0
        let amount10 = 0
        // 内税の税額
        let tax0 = 0
        this.amountTargets.filter(destroy).map((e, i) => {
            const amount = 1.0 * toNumber(e.value)
            const taxType = 1 * this.taxTypeTargets[i].value
            switch (taxType) {
                case 1: // 外税10%
                    amount10 += amount
                    // 合計してから税額を求める
                    break
                case 2: // 内税10%
                    // amount0 += 1.0 * amount / 1.1
                    // tax0 += 1.0 * amount / 1.1 * 0.1
                {
                    const tax = Math.floor(amount / 1.1 * 0.1)
                    amount0 += amount - tax
                    tax0 += tax
                }
                    break
                case 3: // 外税8%
                    amount8 += amount
                    // 合計してから税額を求める
                    break
                case 4: // 内税8%
                    // amount0 += 1.0 * amount / 1.08
                    // tax0 += 1.0 * amount / 1.08 * 0.08
                {
                    const tax = Math.floor(amount / 1.1 * 0.1)
                    amount0 += amount - tax
                    tax0 += tax
                }
                    break
                case 0: // 非課税
                default:
                    amount0 += amount
                    break
            }
        })
        // 消費税の四捨五入
        const tax8 = Math.round(amount8 * 0.08)
        const tax10 = Math.round(amount10 * 0.1)
        // console.debug({
        //     'amount0': amount0,
        //     'amount8': amount8,
        //     'amount10': amount10,
        //     'tax0': tax0,
        //     'tax8': tax8,
        //     'tax10': tax10,
        // })
        this.slipAmountTarget.value = addComma(amount0 + amount8 + amount10)
        this.slipTaxTarget.value = addComma(tax0 + tax8 + tax10)
        this.slipTotalTarget.value = addComma(amount0 + amount8 + amount10 + tax0 + tax8 + tax10)
    }

}
