import {ParentController} from "./parent_controller";

export default class extends ParentController {
    static targets = ['file', 'search', 'csv', 'xlsx', 'detailCsv', 'detailXlsx', 'key']

    clickFile(e) {
        this.fileTarget.click()
    }

    doSearch() {
        this.searchTarget.click()
    }

    doCsv() {
        this.csvTarget.click()
    }

    doXlsx() {
        this.xlsxTarget.click()
    }

    doDetailCsv() {
        this.detailCsvTarget.click()
    }

    doDetailXlsx() {
        this.detailXlsxTarget.click()
    }

    expandDetails(e) {
        const $$ = $(e.target).parents('tr')
        const key = $$.data('key')
        const $details = $('#card-buys-match-details')
        $details.removeClass('d-none')
        const $table = $('#table-buys-match-details tbody')
        $table.find(`tr`).removeClass('d-none').hide()
        const $rows = $table.find(`tr.line-${key}`).show()
        this.keyTarget.value = key
        const text = I18n.t("helpers.page_entries_info.one_page.display_entries", {
            entry_name: I18n.t("activerecord.models.buys_line"),
            count: $rows.length
        })
        $('#my_entries_info').html(text)
        $details[0].scrollIntoView()
    }
}
