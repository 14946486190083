import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['customerType',
        'storeCode', 'storeName', 'storeSearch',
        'searchType', 'displayType1', 'displayType2', 'displayItem'
    ]

    initialize() {
        this.checkCustomerType();
    }

    checkCustomerType() {
        let isConsumer = false;
        this.customerTypeTargets.forEach((el, i) => {
            if (el.checked && ~~el.value === 0) {
                isConsumer = true;
            }
        })

        let searchType = 0;
        this.searchTypeTargets.forEach((el, i) => {
            if (el.checked) {
                searchType = i;
            }
        })

        this.displayItemTargets[3].disabled = !isConsumer;
        this.displayItemTargets[4].disabled = !isConsumer;

        this.displayItemTargets[5].disabled = isConsumer;
        this.displayItemTargets[6].disabled = isConsumer;

        this.displayType1Targets.map((e) => {
            e.disabled = (searchType === 2);
        })
        this.displayType2Targets.map((e) => {
            e.disabled = (searchType !== 1);
        })
    }
}
