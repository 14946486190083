import {ParentController} from "./parent_controller";

export default class extends ParentController {
    static targets = [
        'form', 'date', 'storeCode', 'chargeCode', 'categoryCode', 'articleCode',
        'formRegister', 'dateRegister', 'storeCodeRegister', 'chargeCodeRegister', 'categoryCodeRegister', 'articleCodeRegister'
    ]

    search() {
        window.NProgress.start()
        this.formTarget.submit()
    }

    register() {
        window.NProgress.start()
        $('.input-number').removeComma()
        this.dateRegisterTarget.value = this.dateTarget.value
        this.storeCodeRegisterTarget.value = this.storeCodeTarget.value
        this.chargeCodeRegisterTarget.value = this.chargeCodeTarget.value
        this.categoryCodeRegisterTarget.value = this.categoryCodeTarget.value
        this.articleCodeRegisterTarget.value = this.articleCodeTarget.value
        this.formRegisterTarget.submit()
    }

}
