import {ParentController} from "./parent_controller";

export default class extends ParentController {
    static targets = [
        'form', 'date', 'terminalCode', 'chargeCode', 'salesDate',
        'formRegister', 'dateRegister', 'terminalCodeRegister', 'chargeCodeRegister', 'salesDateRegister'
    ]

    search() {
        this.formTarget.submit()
    }

    register() {
        this.dateRegisterTarget.value = this.dateTarget.value
        this.terminalCodeRegisterTarget.value = this.terminalCodeTarget.value
        this.chargeCodeRegisterTarget.value = this.chargeCodeTarget.value
        this.salesDateRegisterTarget.value = this.salesDateTarget.value
        this.formRegisterTarget.submit()
    }

}
