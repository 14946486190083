import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['customerType',
        'storeCode', 'storeName', 'storeSearch',
        'customerCode', 'customerName', 'customerSearch',
        'consumerCode', 'consumerName', 'consumerSearch',
        'industryCode', 'industryName', 'industrySearch',
        'searchType', 'displayItem'
    ]

    initialize() {
        this.checkCustomerType();
    }

    checkCustomerType() {
        let isConsumer = false;
        this.customerTypeTargets.forEach((el, i) => {
            if (el.checked && ~~el.value === 0) {
                isConsumer = true;
            }
        })

        $(this.customerCodeTarget).parents('.form-row').showIf(!isConsumer);
        $(this.consumerCodeTarget).parents('.form-row').showIf(isConsumer);

        this.industryCodeTarget.disabled = isConsumer;
        this.industryNameTarget.disabled = isConsumer;
        this.industrySearchTarget.disabled = isConsumer;

        this.displayItemTargets[2].disabled = !isConsumer;
        this.displayItemTargets[3].disabled = !isConsumer;

        this.searchTypeTargets[2].disabled = isConsumer;
    }
}
