import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['searchType', 'storeCodes', 'storeNames', 'storesSearch']

    initialize() {
        this.checkSearchType();
    }

    checkSearchType() {
        let toTargetDisable = false;
        this.searchTypeTargets.forEach((el, i) => {
            if(el.checked && ~~el.value === 0) {
                toTargetDisable = true;
            }
        })
        this.storeCodesTarget.disabled = toTargetDisable;
        this.storeNamesTarget.disabled = toTargetDisable;
        this.storesSearchTarget.disabled = toTargetDisable;
    }
}
