import LineController from "./line_controller";
import {toNumber, addComma} from "../utils/utils"

export default class extends LineController {
    static targets = ['code', 'name', 'unit', 'number', 'quantity', 'price', 'cost', 'amount', 'delete', 'addLine', 'destroy']

    calculateAmount(e) {
        const targetId = e.target.id
        // const price = toNumber(this.priceTarget.value)
        const cost = toNumber(this.costTarget.value)
        const unit = toNumber(this.unitTarget.value)
        const number = toNumber(this.numberTarget.value)
        if (unit && number && targetId !== this.quantityTarget.id) {
            this.quantityTarget.value = addComma(unit * number)
        }

        const quantity = toNumber(this.quantityTarget.value)
        if (targetId !== this.amountTarget.id) {
            this.amountTarget.value = addComma(Math.floor(cost * quantity))
        }
        $(this.amountTarget).forceChange()

    }

}
