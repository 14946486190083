import LineController from "./line_controller"
import {addComma, toNumber, hasLeft} from "../utils/utils";

export default class extends LineController {
    static targets = ['code', 'name', 'price', 'cost', 'unit', 'number', 'storeQuantity', 'deliveryQuantity', 'amount', 'taxType', 'profitRate', 'addLine', 'delete', 'destroy', 'supplierName']

    initialize() {
        super.initialize();
        this.calculateAmount()
    }

    calculateAmount(e) {
        const unit = toNumber(this.unitTarget.value)
        const number = toNumber(this.numberTarget.value)
        const cost = toNumber(this.costTarget.value)
        const amount = toNumber(this.amountTarget.value)

        let price = null
        if (unit && amount) {
            price = Math.floor(amount / unit)
            this.priceTarget.placeholder = addComma(price)
        } else {
            this.priceTarget.placeholder = ''
        }

        if (this.priceTarget.value) {
            price = toNumber(this.priceTarget.value)
        }

        const sum = this.storeQuantityTargets.reduce(function (sum, e, i) {
            return sum + (i !== 0 ? toNumber(e.value) : 0)
        }, 0) + this.deliveryQuantityTargets.reduce(function (sum, e) {
            return sum + toNumber(e.value)
        }, 0)
        const sqt = this.storeQuantityTargets[0];
        sqt.placeholder = number - sum
        if (hasLeft(sqt)) {
            $(sqt).addClass('text-danger').addClass('font-weight-bold')
        } else {
            $(sqt).removeClass('text-danger').removeClass('font-weight-bold')
        }

        const a_cost = unit === 0 ? 0 : cost / unit
        this.profitRateTarget.value = !(price > 0) || a_cost === 0 ? null : Math.floor((1.0 - a_cost / price) * 10000) / 100
    }

}
