import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['searchType', 'categoryCode', 'categorySearch', 'articleCode', 'articleSearch']

    initialize() {
        this.checkSearchType();
    }

    checkSearchType() {
        let isCategorySearch = false;
        this.searchTypeTargets.forEach((el, i) => {
            if (el.checked && ~~el.value === 1) {
                isCategorySearch = true;
            }
        })
        this.categoryCodeTarget.disabled = !isCategorySearch;
        this.categorySearchTarget.disabled = !isCategorySearch;
        this.articleCodeTarget.disabled = isCategorySearch;
        this.articleSearchTarget.disabled = isCategorySearch;
    }
}
