import ArticleSingleController from './article_single_controller'

export default class extends ArticleSingleController {
    static targets = ['code', 'name', 'modal', 'iframe', 'price', 'cost', 'quantity', 'taxType', 'noHeader']

    searchCode(e) {
        super.searchCode(e)
        if (e.keyCode === 13) {
            e.preventDefault()
            const $$ = $(e.target)
            $$.focusNext($$.closest("form"))
        }
    }
}
